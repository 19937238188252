
import { PopoutsModule } from "@/store/modules/popouts";
import Servers from "./Servers.vue";

import { defineComponent } from "vue";
export default defineComponent({
  name: "SideBar",
  components: { Servers },
  methods: {
    addServerClicked() {
      PopoutsModule.ShowPopout({
        component: "add-server-popout",
        id: "add-server",
      });
    },
  },
});
