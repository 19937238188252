
import AvatarImage from "@/components/AvatarImage.vue";
import Server from "@/interfaces/Server";
import { LastSeenServerChannelsModule } from "@/store/modules/lastSeenServerChannel";
import { PopoutsModule } from "@/store/modules/popouts";
import { LastSelectedServersModule } from "@/store/modules/lastSelectedServer";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "MainApp",
  components: { AvatarImage },
  props: {
    server: {
      type: Object as PropType<Server>,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    path(): any {
      const serverChannelID = LastSelectedServersModule.lastServerChannelID(
        this.server.server_id || ""
      );
      return `/app/servers/${this.server.server_id}/${
        serverChannelID || this.server.default_channel_id
      }`;
    },
    mentionedNotificationExists(): any {
      return this.serverNotificationArr.find((c) => c.mentioned);
    },
    serverNotificationArr(): any {
      return LastSeenServerChannelsModule.serverNotifications(
        this.server.server_id
      );
    },
    isServerSelected(): any {
      return this.$route.params.server_id === this.server.server_id;
    },
  },
  methods: {
    showContext(event: MouseEvent) {
      PopoutsModule.ShowPopout({
        id: "context",
        component: "ServerContextMenu",
        key: this.server.server_id + event.clientX + event.clientY,
        data: {
          x: event.clientX,
          y: event.clientY,
          server_id: this.server.server_id,
        },
      });
    },
  },
});
