
import _ from "lodash";
import { defineAsyncComponent } from "vue";

const ServerDrawer = defineAsyncComponent(
  () => import("@/components/drawers/server-drawer/ServerDrawer.vue")
);
const ServerSettingsDrawer = defineAsyncComponent(
  () => import("@/components/drawers/server-drawer/ServerSettingsDrawer.vue")
);

const SettingsDrawer = defineAsyncComponent(
  () => import("@/components/drawers/SettingsDrawer.vue")
);
const DirectMessageDrawer = defineAsyncComponent(
  () =>
    import("@/components/drawers/direct-message-drawer/DirectMessageDrawer.vue")
);
const ExploreDrawer = defineAsyncComponent(
  () => import("@/components/drawers/ExploreDrawer.vue")
);
const AdminPanelDrawer = defineAsyncComponent(
  () => import("@/components/drawers/AdminPanelDrawer.vue")
);

import SideBar from "@/components/sidebar/SideBar.vue";

import { ServersModule } from "@/store/modules/servers";
import { PopoutsModule } from "@/store/modules/popouts";

import { defineComponent } from "vue";
export default defineComponent({
  name: "MainApp",
  components: {
    SideBar,
    ServerDrawer,
    SettingsDrawer,
    DirectMessageDrawer,
    ServerSettingsDrawer,
    ExploreDrawer,
    AdminPanelDrawer,
  },
  computed: {
    headerName(): any {
      if (this.selectedServer) {
        return this.selectedServer.name;
      }
      return _.capitalize(this.currentTab);
    },
    selectedServerID(): any {
      return this.$route.params.server_id;
    },
    currentTab(): any {
      return this.$route.path.split("/")[2];
    },
    showServerSettingsDrawer(): any {
      return this.$route.name === "server-settings";
    },
    showServerDrawer(): any {
      return this.currentTab === "servers" && !this.showServerSettingsDrawer;
    },
    selectedServer(): any {
      return ServersModule.servers[this.selectedServerID];
    },
  },
  methods: {
    showServerContext(event: any) {
      PopoutsModule.ShowPopout({
        id: "context",
        component: "ServerContextMenu",
        key: this.selectedServerID + event.clientX + event.clientY,
        data: {
          x: event.clientX,
          y: event.clientY,
          server_id: this.selectedServerID,
        },
      });
    },
  },
});
